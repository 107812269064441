<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.2499 0H9.74992C9.33742 0 8.99992 0.335938 8.99992 0.75C8.99992 1.16406 9.33586 1.5 9.74992 1.5H11.4393L8.80617 4.13438C7.82805 3.39375 6.66867 3 5.49992 3C4.06555 3 2.68492 3.5375 1.61086 4.60938C-0.536953 6.75719 -0.536953 10.2406 1.61086 12.3875C2.68492 13.4625 4.06555 14 5.49992 14C6.9343 14 8.30961 13.4631 9.3843 12.3891C11.3505 10.4228 11.5002 7.35156 9.8668 5.19531L12.4999 2.55938V4.25C12.4999 4.66563 12.8374 5 13.2499 5C13.6624 5 13.9999 4.66563 13.9999 4.25V0.75C13.9999 0.335938 13.6655 0 13.2499 0ZM8.32805 11.3281C7.5718 12.0844 6.56867 12.5 5.49992 12.5C4.43117 12.5 3.42711 12.0841 2.67148 11.3284C1.1118 9.76875 1.1118 7.23156 2.67148 5.67219C3.42805 4.91562 4.43117 4.5 5.49992 4.5C6.56867 4.5 7.57305 4.91625 8.32836 5.67188C8.32848 5.672 8.32824 5.67175 8.32836 5.67188C8.32842 5.67194 8.3283 5.67181 8.32836 5.67188C9.88742 7.23125 9.88742 9.76875 8.32805 11.3281Z" />
  </svg>
</template>
